import { Box, Flex, Image, Text, useMatchBreakpoints } from "@pancakeswap/uikit";
import Container from "components/Layout/Container";
import { ColLeft, ContainerBanner, ContainerImage, CsHeading, RowForBanner } from "./styles";

const Banner = () => {
    const { isMobile } = useMatchBreakpoints();
    return (
        <ContainerBanner>
            <Container width="100%" height="100%">
                <RowForBanner>
                    <ColLeft>
                        <Flex width="100%" flexDirection="column" style={{gap:'1rem'}}>
                            <Flex width="100%">
                                <CsHeading color="white">300+ thương hiệu nổi tiếng trên toàn quốc</CsHeading>
                            </Flex>
                            <Box width="100%" maxWidth="600px">
                                <Flex width="100%">
                                    <Text color="white" fontSize={['12px', '14px' , '16px']}>Nền tảng đầu tiên cho phép sử dụng <span style={{color: 'white', whiteSpace: 'nowrap', fontWeight: '600'}}>điểm thưởng</span> để đổi voucher</Text>
                                </Flex>
                                <ContainerImage>
                                    <Image
                                        src="/images/giftcards/dnpay.png?version=1.0"
                                        width={ isMobile ? 65 : 81}
                                        height={ isMobile ? 22 :28 }
                                    />
                                    <Image
                                        src="/images/giftcards/bsc.png?version=1.0"
                                        width={ isMobile ? 82 : 102 }
                                        height={ isMobile ? 22 :28 }
                                    />
                                    <Image
                                        src="/images/giftcards/onus.png?version=1.0"
                                        width={ isMobile ? 85 : 105 }
                                        height={ isMobile ? 22 :28 }
                                    />
                                </ContainerImage>
                            </Box>
                        </Flex>
                    </ColLeft>
                </RowForBanner>
            </Container>
        </ContainerBanner>
    )
}

export default Banner