
interface BankType {
    name: string,
    isDisable: boolean,
    image:string
    isActive: boolean
}

interface TradeMarkType {
    name: string,
    image:string
}

export const listBank:BankType[] = [
    {
        name: "vndc",
        isDisable: false,
        isActive: !false,
        image:"/images/logo/vndc2.png"
    },
    {
        name: "mb",
        isDisable: false,
        isActive: !false,
        image:"/images/logo/dnpay.png"
    },
    {
        name: "vietcom",
        isDisable: !false,
        isActive: false,
        image:"/images/logo/vietcom.png"
    },
    {
        name: "vietin",
        isDisable: !false,
        isActive: false,
        image:"/images/logo/vietin.png"
    },
    {
        name: "acb",
        isDisable: !false,
        isActive: false,
        image:"/images/logo/acb.png"
    }

]

export const listTradeMark: TradeMarkType[] = [
    {
        name: "highlands",
        image: "/images/logo/highlands.png"
    },
    {
        name: "phuclong",
        image: "/images/logo/phuclong.png"
    },
    {
        name: "coopmart",
        image: "/images/logo/coopmart.png"
    },
    {
        name: "dingtea",
        image: "/images/logo/dingtea.png"
    },
    {
        name: "jollibee",
        image: "/images/logo/jollibee.png"
    },
    {
        name: "cgv",
        image: "/images/logo/cgv.png"
    },
    {
        name: "vascara",
        image: "/images/logo/vascara.png"
    },
    {
        name: "manuah",
        image: "/images/logo/manuah.png"
    },
    {
        name: "sulwhasoo",
        image: "/images/logo/dingtea.png"
    },
    {
        name: "levis",
        image: "/images/logo/levis.png"
    },
    {
        name: "br",
        image: "/images/logo/br.png"
    },
    {
        name: "kfc",
        image: "/images/logo/kfc.png"
    },
    {
        name: "galaxyplay",
        image: "/images/logo/galaxyplay.png"
    },
    {
        name: "go",
        image: "/images/logo/go.png"
    },
    {
        name: "starbucks",
        image: "/images/logo/starbucks.png"
    },
]