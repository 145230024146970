import React from "react";
import { Flex, Heading } from "@pancakeswap/uikit";
import styled, { css } from "styled-components";
import { MENU_HEIGHT } from "@pancakeswap/uikit/src/widgets/Menu/config";

export const ColLeft = styled(Flex)`
    width: 61.5%;
    height: auto;
    @media screen and (max-width:768px) {
        width:100%;  
    }
`

export const ColRight = styled(Flex)`
    width: 30%;
    height: auto;
`
export const RowForBanner = styled(Flex)`
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    margin-top: 150px;
    ${ColLeft} {
        margin-top: 60px;
        @media screen and (max-width:768px) {
            margin-top: 20px;
        }
    }
    @media screen and (max-width:768px) {
        margin-top: 120px;
    }
`
export const ContainerBanner = styled(Flex)`
    width: 100%;
    margin-top:-${MENU_HEIGHT}px;
    height: auto;
    min-height: 100vh;
    background: url("/images/giftcards/banner.png");
    background-size: 100% 100%;
    background-position: center top;
    background-repeat: no-repeat;
    z-index: 1;
    @media screen and (max-width: 768px) and (min-width:601px) {
        height: 1100px;
    }
    @media screen and (max-width: 600px){
        height: 1000px;
    }
`
export const CsHeading = styled(Heading)`
    font-size: 60px;
    color: ${({ theme }) => theme.colors.white};
    font-weight: 700;
    line-height: 120%; /* 72px */
    letter-spacing: -1.2px;
    @media screen and (max-width:1280px) and (min-width:1001px) {
        font-size: 50px;
    }
    @media screen and (max-width:600px) {
        font-size: 36px;
    }
`
export const CardImg = styled(Flex)<{isActive?:boolean, isDisable?:boolean}>`
    width: 100%;
    height: 36px;
    align-items: center;
    justify-content: center;
    /* padding: 4px; */
    border-radius: 6px;
    /* border: ${({ theme, isActive }) => isActive ? `2px solid ${theme.colors.gold}` : "none"}; */
    cursor: ${({ isDisable }) => isDisable ? "not-allowed" : "default"};
    > img {
        ${(props) =>
            props.isDisable &&
        css`
            mix-blend-mode: luminosity;
            opacity: 0.7;
        `}
    }
`
export const GirdImage = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 8px;
    gap: 2px;
`

export const ContainerImage = styled(Flex)`
    height: 60px;
    width: 384px;
    padding: 0px 16px;
    background: white;
    border-radius: 16px;
    justify-content: space-around;
    align-items: center;
    margin-top: 12px;
    @media screen and (max-width: 576px) {
        width: 100%;
    }
`